<template>
  <div>
    <Header>忘记交易密码</Header>
    <div class="form">
      <div class="phone">
        <span class="phone_number white_color">+ 86</span>
        <van-form ref="form" validate-first>
          <van-field
            v-model="tel"
            class="black_bg background white_color"
            type="text"
            :disabled="!!tel"
            :placeholder="loginType == '1' ? '请输入手机号码' : '请输入邮箱'"
          />
        </van-form>
      </div>
      <div class="verify-code">
        <van-field
          v-model="code"
          class="black_bg background"
          placeholder="请输入图形验证码"
        />
        <img :src="CodeImg" @click="getGraphicalCode" alt="" />
        <div class="verify-code_right none1" v-if="CodeImg == ''">
          <span
            class="white_color"
            style="font-size: 14px"
            @click="getGraphicalCode"
            >获取图形验证码</span
          >
        </div>
      </div>
      <div class="verify-code">
        <van-field
          v-model="verifyCode"
          class="black_bg background"
          maxlength="6"
          placeholder="请输入验证码"
        />
        <div class="verify-code_right">
          <span
            class="white_color"
            style="font-size: 14px"
            @click="getVerification"
            >{{ codetxt }}</span
          >
        </div>
      </div>
      <div class="password">
        <van-field
          v-model="password"
          class="black_bg background"
          :type="passwordType"
          maxlength="6"
          placeholder="请输入交易密码"
        />
        <div class="eye">
          <img
            :src="passwordType === 'password' ? closeEye : openEye"
            @click="changeType(passwordType, 'passwordType')"
          />
        </div>
      </div>
      <div class="password">
        <van-field
          v-model="confirmPwd"
          class="black_bg background"
          :type="passwordType1"
          maxlength="6"
          placeholder="请再次输入交易密码"
        />
        <div class="eye">
          <img
            :src="passwordType1 === 'password' ? closeEye : openEye"
            @click="changeType(passwordType1, 'passwordType1')"
          />
        </div>
      </div>
      <div class="btn" :class="active ? 'active' : ''" @click="confirm">
        确定
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼
export default {
  data() {
    return {
      openEye,
      closeEye,
      tel: '',
      CodeImg: '', // 图片验证码
      emailCodeImg: '', // 图片验证码
      verifyCode: '',
      codetxt: '获取验证码',
      password: '',
      confirmPwd: '',
      code: '', // 图形验证码
      passwordType: 'password',
      passwordType1: 'password',
      loginType: '1',
    };
  },
  computed: {
    active() {
      if (!this.tel) return false;
      if (!this.code) return false;
      if (!this.verifyCode) return false;
      if (!this.password) return false;
      if (!this.confirmPwd) return false;
      return true;
    },
  },
  mounted() {
    this.loginType = localStorage.getItem('loginType');
    this.$api
      .anyUserinfo({ token: localStorage.getItem('token') })
      .then((e) => {
        if (e.code === 0) {
          if (this.loginType == '1') {
            this.tel = e.data.contactTel;
          } else {
            this.tel = e.data.email;
          }
        }
      });
  },
  watch: {
    number(v) {
      console.log(v);
    },
  },
  methods: {
    changeType(type, key) {
      if (type === 'password') {
        this[key] = 'Number';
      } else {
        this[key] = 'password';
      }
    },
    // 确认密码
    confirm() {
      let text = '请输入手机号';
      if (this.loginType != '1') {
        text = '请输入邮箱';
      }
      if (!this.tel) {
        this.$toast(text);
        return;
      }
      if (!this.code) {
        this.$toast('请输入图形验证码');
        return;
      }
      if (!this.password) {
        return this.$toast('请输入密码');
      }
      // if (!Number.isFinite(this.password)) {
      //   return this.$toast('请输入数字类型');
      // }

      if (this.password.length < 6) {
        return this.$toast('请输入6位密码');
      }
      if (parseInt(this.password).toString().length < 6) {
        return this.$toast('密码必须为6位数字');
      }
      if (this.password !== this.confirmPwd) {
        return this.$toast('两次密码输入不一致');
      }
      const data = {
        type: this.loginType == '1' ? 1 : 2,
        name: this.tel,
        checkCode: this.verifyCode,
        pwd: this.password,
        confirmPwd: this.confirmPwd,
        token: localStorage.getItem('token'),
      };
      this.$api.passwordForget(data).then((res) => {
        this.$toast(res.msg);
        if (res.code === 0) {
          this.$router.back();
        }
      });
    },
    // 获取验证码
    getVerification() {
      if (this.codetxt !== '获取验证码') return;
      let text = '请输入手机号';
      if (this.loginType != '1') {
        text = '请输入邮箱';
      }
      if (!this.tel) {
        this.$toast(text);
        return;
      }
      if (!this.code) {
        this.$toast('请输入图形验证码');
        return;
      }
      let params = {
        tel: this.tel,
        email: this.tel,
        code: this.code,
      };
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let fun = this.$api.anyCheckcodeforget;
      if (this.loginType != '1') {
        fun = this.$api.anyCheckcodeforgetForEmail;
      }
      fun(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示
          toast.clear();
          // this.$toast.success('验证码已发送')
          Toast.success({
            message: '验证码已发送',
            forbidClick: true,
          });
          let n = 60;
          this.timer = setInterval(() => {
            this.codetxt = `${n--}s`;
            if (n == 0) {
              this.codetxt = '获取验证码';
              clearInterval(this.timer);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail('获取失败');
        }
      });
    },
    // 获取图片验证码
    getGraphicalCode() {
      let text = '请输入手机号';
      if (this.loginType != '1') {
        text = '请输入邮箱';
      }
      if (!this.tel) {
        return this.$toast(text);
      }
      this.$refs.form
        .validate()
        .then(() => {
          if (this.loginType == '1') {
            this.$api.getGraphicalCode({ tel: this.tel }).then((res) => {
              this.$toast.success('获取成功');
              this.CodeImg = (URL || webkitURL).createObjectURL(
                new Blob([res], { type: 'img/jpeg' }),
              );
            });
          } else {
            this.$api.getEmailGraphicalCode({ email: this.tel }).then((res) => {
              this.$toast.success('获取成功');
              this.CodeImg = (URL || webkitURL).createObjectURL(
                new Blob([res], { type: 'img/jpeg' }),
              );
            });
          }
        })
        .catch(() => {
          this.$toast.fail('获取失败');
        });
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  font-size: 16px;
  color: #c2c2c2;

  /deep/ .van-field__control {
    color: #333 !important;
  }
  .van-cell {
    padding: 10px 0;
  }

  .phone {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 5px 0;

    .phone_number {
      display: flex;
      width: 16%;
      padding: 0 10px 0 0px;
      color: #333;
    }

    .phone_number::after {
      content: ' ';
      display: flex;
      width: 1px;
      height: 13px;
      margin-left: 10px;
      margin-top: 6px;
      background: rgb(108, 104, 104);
    }

    .emailar {
      width: 40%;
    }
  }

  .verify-code {
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    padding: 5px 0;

    .verify-code_right {
      display: flex;
      width: 48%;
      align-items: center;
      font-size: 15px;

      span {
        display: flex;
        align-items: center;
      }

      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-right: 10px;
        background: rgb(108, 104, 104);
      }
    }
    .none1 {
      span::before {
        content: ' ';
        display: flex;
        width: 0px;
        height: 0px;
        margin-right: 10px;
        background: rgba(218, 218, 218, 1);
      }
    }
  }

  .tuijianma {
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    padding: 5px 0;

    .tuijianma_right {
      display: flex;
      width: 48%;
      align-items: center;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;
      }

      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-right: 10px;
        background: rgba(218, 218, 218, 1);
      }
    }
  }

  .password {
    display: flex;
    border-bottom: 1px solid #eee;
    align-items: center;
    padding: 5px 0;

    span {
      display: flex;
      width: 38%;
      align-items: center;
    }

    span::before {
      content: ' ';
      display: flex;
      width: 1px;
      height: 30px;
      margin-right: 10px;
      background: #eeeeee;
    }

    .eye {
      display: flex;

      img {
        width: 17px;
        height: auto;
        padding: 0 20px;
      }
    }
  }

  .button {
    margin-top: 30px;

    .van-button--primary {
      color: #fff;
      background: #0754d3;
      border: 1px solid #0754d3;
    }

    .van-button--default {
      color: #fff !important;
      // background-color: rgba(51, 51, 51, 1) !important;
      // 邮箱注册按钮颜色
      background-color: blue;
      border: 0;
    }
  }

  .van-button--default {
    background: #0754d3;
    // background-color: blue;
  }
}
.white_color {
  color: #0754d3;
}
.btn {
  margin-top: 50px;
  background: #aaa;
  width: 100%;
  height: 35px;
  font-size: 15px;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    background: #0754d3;
  }
}
</style>
